import React from 'react';
import styled from 'styled-components';
import { AUDIT_DATA } from 'views/technical-due-diligence/_constants';
import { AuditTile } from 'views/technical-due-diligence/content-section/components/audit-tile';
import { CONSTANTS } from 'constants/styles/constants';

const SWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 5.625rem 3.875rem;
    ${CONSTANTS.MEDIA.max500`
        grid-template-columns: 1fr;
   `}
`;

const SGroupWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2rem;
`;

export const AuditContent = () => {
    const auditDataToRender = AUDIT_DATA.map((item, index) => {
        if (Array.isArray(item)) {
            return (
                <SGroupWrapper>
                    {item.map((groupItem) => {
                        return <AuditTile key={index} item={groupItem} />;
                    })}
                </SGroupWrapper>
            );
        }

        return <AuditTile key={index} item={item} />;
    });

    return <SWrapper>{auditDataToRender}</SWrapper>;
};
