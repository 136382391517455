import React from 'react';
import { TechnicalDueDiligenceView } from 'views/technical-due-diligence';
import { SEO } from 'components/seo';
import { TAGS } from 'constants/tags';
import messages from 'constants/translations/audit.json';
import Layout from 'layouts/standard';
import { object } from 'prop-types';

const TechnicalDueDiligence = (props) => {
    const { location } = props;
    return (
        <Layout location={location} messages={messages}>
            <TechnicalDueDiligenceView />
        </Layout>
    );
};

TechnicalDueDiligence.propTypes = {
    location: object.isRequired,
};

export default TechnicalDueDiligence;

export const Head = () => <SEO tags={TAGS.TECHNICAL_DUE_DILIGENCE} />;
