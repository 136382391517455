import React from 'react';
import Bank from 'svgs/icons/ic-bank.svg';
import ChartSquare from 'svgs/icons/ic-chart-square.svg';
import CheckInCircle from 'svgs/icons/ic-check-in-circle.svg';
import DocumentText from 'svgs/icons/ic-document-text.svg';
import FavoriteChart from 'svgs/icons/ic-favorite-chart.svg';
import Health from 'svgs/icons/ic-health.svg';
import People from 'svgs/icons/ic-people-alt.svg';
import ProfileTick from 'svgs/icons/ic-profile-tick.svg';
import SearchStatus from 'svgs/icons/ic-search-status-bold.svg';
import ShieldTick from 'svgs/icons/ic-shield-tick-bold.svg';
import StickyNote from 'svgs/icons/ic-stickynote.svg';
import TaskSquare from 'svgs/icons/ic-task-square.svg';
import Warning from 'svgs/icons/ic-warning-2.svg';

export const AUDIT_DATA = [
    {
        icon: <DocumentText width={32} height={32} />,
        key: 'documentationReview',
        listKeys: [
            'descriptions',
            'functionalities',
            'database',
            'descriptions',
            'infrastructure',
        ],
    },
    {
        icon: <TaskSquare width={32} height={32} />,
        key: 'implementationControl',
        listKeys: ['review', 'process', 'verification', 'debt'],
    },
    {
        icon: <People width={32} height={32} />,
        key: 'interview',
        listKeys: ['control', 'documentation', 'process', 'automation'],
    },
    [
        {
            icon: <Health width={32} height={32} />,
            key: 'financials',
            listKeys: ['costs', 'monetization'],
        },
        {
            icon: <SearchStatus width={32} height={32} />,
            key: 'seo',
            listKeys: ['improvements'],
        },
    ],
    {
        icon: <CheckInCircle width={32} height={32} />,
        key: 'evaluation',
        listKeys: ['integration'],
    },
    {
        icon: <StickyNote width={32} height={32} />,
        key: 'documentation',
        listKeys: ['maintenance'],
    },
    {
        icon: <Bank width={32} height={32} />,
        key: 'audit',
        listKeys: ['review', 'check'],
    },
    {
        icon: <ShieldTick width={32} height={32} />,
        key: 'security',
        listKeys: ['roles', 'dependencies'],
    },
    {
        icon: <Warning width={32} height={32} />,
        key: 'risk',
        listKeys: ['recommendations'],
    },
    {
        icon: <ProfileTick width={32} height={32} />,
        key: 'accessibility',
        listKeys: ['evaluation'],
    },
    {
        icon: <ChartSquare width={32} height={32} />,
        key: 'analysis',
        listKeys: ['positioning', 'benchmarking'],
    },
    {
        icon: <FavoriteChart width={32} height={32} />,
        key: 'scalability',
        listKeys: ['assessment', 'trends'],
    },
];

const AUDIT_PROCESS_ITEMS_KEYS = ['intro-call', 'qa', 'analysis', 'report'];

export const AUDIT_PROCESS = AUDIT_PROCESS_ITEMS_KEYS.map((key) => ({
    title: `audit.process.${key}.title`,
    desc: `audit.process.${key}.description`,
}));
