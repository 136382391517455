import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { RequestSection } from 'modules/request-section';

export const AuditRequestSection = () => {
    const images = useStaticQuery(graphql`
        {
            ceo: file(relativePath: { eq: "team/marcin-ceo.png" }) {
                childImageSharp {
                    gatsbyImageData(
                        quality: 100
                        placeholder: NONE
                        width: 363
                        formats: [WEBP]
                    )
                }
            }
        }
    `);

    return (
        <RequestSection
            imagesData={images}
            alt="Marcin Sadowski"
            title="audit.letsTalk"
            description="audit.eachPoint"
            isContact
        />
    );
};
