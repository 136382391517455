import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { Container } from 'components/container';
import { HeaderSecond } from 'components/header-second';
import { Paragraph } from 'components/paragraph';
import { CONSTANTS } from 'constants/styles/constants';
import SolutionsIllustration from 'svgs/expertises/solutions.svg';
import TickCircleSvg from 'svgs/icons/ic-tick-circle.svg';

const SContainer = styled.div`
    background-color: var(--gray-color-90);
    padding: 4.125rem 5rem;
    display: flex;
    gap: 8rem;

    ${CONSTANTS.MEDIA.max1024`
        flex-direction: column;
        padding: 2rem;
        gap: 3rem;
    `}
`;

const SLeftCol = styled.div`
    flex: 0.4;
    width: 100%;
    height: 100%;
    text-align: end;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.25rem;

    ${CONSTANTS.MEDIA.max1024`
        text-align: center;
    `}
`;

const SSolutionsIllustration = styled(SolutionsIllustration)`
    width: 100%;
    height: 100%;
    max-width: 28.125rem;
    max-height: 23.75rem;
`;

const STextWrapper = styled.div`
    flex: 0.6;
`;

const STitle = styled(HeaderSecond)`
    font-size: 1.125rem;
    font-weight: 700;
`;

const SParagraph = styled(Paragraph)`
    grid-column: 2 / 4;
    font-size: 1rem;
    font-weight: 400;
`;

const SDetail = styled.div`
    display: flex;
    gap: 1rem;
    margin-bottom: 2.2rem;
`;

const SDetailContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
`;
const STickSvg = styled(TickCircleSvg)`
    min-width: 2rem;
`;
const perkDetailsKeys = [
    'review',
    'evaluation',
    'infrastructure',
    'security',
    'quality',
];

export const PerksSection = () => {
    return (
        <Container>
            <SContainer>
                <SLeftCol>
                    <SSolutionsIllustration />
                    <HeaderSecond>
                        <FormattedMessage id="audit.perks.title" />
                    </HeaderSecond>
                    <Paragraph>
                        <FormattedMessage id="audit.perks.description" />
                    </Paragraph>
                </SLeftCol>
                <STextWrapper>
                    {perkDetailsKeys.map((key) => {
                        const detail = `audit.perks.${key}`;
                        return (
                            <SDetail key={key}>
                                <STickSvg width={32} height={32} />
                                <SDetailContent>
                                    <STitle>
                                        <FormattedMessage
                                            id={`${detail}.title`}
                                        />
                                    </STitle>
                                    <SParagraph>
                                        <FormattedMessage
                                            id={`${detail}.description`}
                                        />
                                    </SParagraph>
                                </SDetailContent>
                            </SDetail>
                        );
                    })}
                </STextWrapper>
            </SContainer>
        </Container>
    );
};
