import React from 'react';
import { useIntl } from 'react-intl';
import { AUDIT_PROCESS } from 'views/technical-due-diligence/_constants';
import { AboutSection } from 'views/technical-due-diligence/audit-about-section';
import { ContentSection } from 'views/technical-due-diligence/content-section';
import { ProcessSection } from 'modules/process-section';
import { WorkingWithUsSection } from 'modules/working-with-us';
import { MainSection } from 'src/views/technical-due-diligence/main-section';
import { PerksSection } from 'src/views/technical-due-diligence/perks-section';
import { AuditRequestSection } from 'src/views/technical-due-diligence/request-section';
import { AuditRoadmapSection } from 'src/views/technical-due-diligence/roadmap-section';

export const TechnicalDueDiligenceView = () => {
    const { formatMessage } = useIntl();

    return (
        <>
            <MainSection />
            <AboutSection />
            <PerksSection />
            <ContentSection />
            <ProcessSection
                title="audit.process.title"
                description="audit.process.description"
                data={AUDIT_PROCESS}
                headerWidth="17rem"
            />
            <WorkingWithUsSection
                title="audit.workingWithUs"
                descriptions={[
                    formatMessage({
                        id: 'audit.ourTeam',
                    }),
                ]}
                alt="audit.imgAlts.workingWithTeam"
                isReversed
            />
            <AuditRoadmapSection />
            <AuditRequestSection />
        </>
    );
};
