import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { Container } from 'components/container';
import { HeaderSecond } from 'components/header-second';

const SHeader = styled(HeaderSecond)`
    font-weight: 300;
    text-align: center;
    margin: 0 auto 5rem;
`;

export const AboutSection = () => {
    return (
        <Container>
            <SHeader>
                <FormattedMessage id="audit.about" />
            </SHeader>
        </Container>
    );
};
