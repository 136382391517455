import React from 'react';
import { Container } from 'components/container';
import { EXTERNAL_REDIRECTS } from 'constants/external-redirects';
import { gaTrackedEvents } from 'constants/ga-tracked-events';
import { PATHS } from 'constants/paths';
import { Specification } from 'modules/projects/components/specification';
import { trackCustomEventGoogleAnalytics } from 'utilities/index';

export const MainSection = () => {
    const googleAnalytics = (value) => {
        trackCustomEventGoogleAnalytics(value);
    };

    return (
        <Container>
            <Specification
                texts={{
                    topSubTitle: 'audit.header',
                    title: 'audit.headline',
                    subtitle: 'audit.info1',
                    buttonFirst: 'general.estimateProject',
                    buttonSecond: 'general.introCall',
                }}
                firstBtnOptions={{
                    navigateTo: PATHS.ESTIMATE_THE_PROJECT,
                    handleClick: () =>
                        googleAnalytics(
                            gaTrackedEvents.SERVICES.CTA.ESTIMATE_PROJECT,
                        ),
                }}
                secondBtnOptions={{
                    component: 'a',
                    navigateTo: EXTERNAL_REDIRECTS.HUB_SPOT_MEETING,
                    handleClick: () =>
                        googleAnalytics(
                            gaTrackedEvents.SERVICES.CTA.INTRO_CALL,
                        ),
                }}
                isBoxStyle
            />
        </Container>
    );
};
