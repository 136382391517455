import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { Container } from 'components/container';
import { HeaderSecond } from 'components/header-second';
import { Paragraph } from 'components/paragraph';
import { CONSTANTS } from 'constants/styles/constants';
import AuditSvg from 'svgs/expertises/audit.svg';

const STitle = styled(HeaderSecond)`
    font-size: 2.25rem;
    margin-bottom: 1.25rem;
`;

const SWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 7rem;

    ${CONSTANTS.MEDIA.max1024`
        align-items: center;
        display: flex;
        flex-direction: column;
        gap: 2rem;
    `}
`;

const SContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2rem;
`;

const SSvg = styled(AuditSvg)`
    ${CONSTANTS.MEDIA.max1024`
        transform: scale(0.6);
    `}
`;

export const AuditRoadmapSection = () => {
    return (
        <Container>
            <SWrapper>
                <SContent>
                    <STitle>
                        <FormattedMessage id="audit.yourRoadmap" />
                    </STitle>
                    <Paragraph>
                        <FormattedMessage id="audit.technicalDueDiligence" />
                    </Paragraph>
                </SContent>
                <SSvg />
            </SWrapper>
        </Container>
    );
};
