import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { HeaderThird } from 'components/header-third';
import { Paragraph } from 'components/paragraph';
import { node, shape, string } from 'prop-types';

const SWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

const IconWrapper = styled.div`
    width: 4rem;
    height: 4rem;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--gray-color-90);
    margin-bottom: 1.75rem;
`;

const SHeaderThird = styled(HeaderThird)`
    margin-bottom: 1rem;
`;

const SList = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
`;

const SStyledListItem = styled.div`
    display: flex;
    flex-direction: column;

    span {
        font-size: 1rem;
    }
`;

const SStyledListItemTitle = styled.h3`
    font-size: 1.125rem;
    font-weight: 700;
`;

export const AuditTile = ({ item: { icon, key, listKeys } }) => {
    const listToRender = listKeys.map((listKey) => (
        <SStyledListItem key={listKey}>
            <SStyledListItemTitle>
                <FormattedMessage id={`audit.${key}.${listKey}.title`} />
            </SStyledListItemTitle>
            <Paragraph>
                <FormattedMessage id={`audit.${key}.${listKey}.description`} />
            </Paragraph>
        </SStyledListItem>
    ));

    return (
        <SWrapper>
            <IconWrapper>{icon}</IconWrapper>
            <SHeaderThird>
                <FormattedMessage id={`audit.${key}.title`} />
            </SHeaderThird>
            <SList>{listToRender}</SList>
        </SWrapper>
    );
};

AuditTile.propTypes = {
    item: shape({
        icon: node,
        title: string,
    }).isRequired,
};

AuditTile.defaultProps = {
    item: {
        description: '',
        list: [''],
    },
};
