import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { AuditContent } from 'views/technical-due-diligence/content-section/components/audit-content';
import { Container } from 'components/container';
import { HeaderSecond } from 'components/header-second';
import { CONSTANTS } from 'constants/styles/constants';

const SSectionHeader = styled.div`
    width: 100%;
    text-align: center;
    margin-bottom: 8rem;

    h2 {
        ${CONSTANTS.MEDIA.min1024`
            font-size: 3.625rem;
            white-space: pre-wrap;
        `}
    }
`;

export const ContentSection = () => {
    return (
        <Container>
            <SSectionHeader>
                <HeaderSecond>
                    <FormattedMessage id="audit.informationTechnology" />
                </HeaderSecond>
            </SSectionHeader>
            <AuditContent />
        </Container>
    );
};
